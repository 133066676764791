import { enUS as mEnUS, zhCN as mZhCN } from "@material-ui/core/locale";
import enUS from "antd/lib/locale-provider/en_US";
import zhCN from "antd/lib/locale-provider/zh_CN";
import NextI18Next from "next-i18next";
import path from "path";

export default new NextI18Next({
  defaultLanguage: "en",
  otherLanguages: ["zh-CN"],
  browserLanguageDetection: true,
  fallbackLng: "en",
  localePath: path.resolve("./public/static/locales"),
  shallowRender: true,
  debug: false && process.env.NODE_ENV === "development",
});

export const antdI18Map = {
  en: enUS,
  "zh-CN": zhCN,
};

export const muiI18Map = {
  en: mEnUS,
  "zh-CN": mZhCN,
};
